<script setup lang="ts">
import { WsButton } from '@mfl/common-components';

import strings from './employee-portal-signin.strings';

function mockSignin() {
  sessionStorage.setItem('MOCK_SIGNIN', 'mock');
  location.pathname = '/';
}
</script>

<template>
  <div class="employee-portal-signin-container">
    <WsButton
      aid="EMPLOYEE_PORTAL_SIGNIN_BUTTON"
      class="my-button"
      color="primary"
      :label="strings.signinButtonLabel"
      @click="mockSignin()"
    />
  </div>
</template>

<style scoped>
.employee-portal-signin-container {
  display: flex;
  margin: 12px 24px;
  flex-direction: column;

  .header {
    display: flex;
  }

  .my-button {
    margin-top: 200px;
    /* width: 300px;
    height: 200px; */
    align-self: center;
  }
}
</style>
