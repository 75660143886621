import { useQuasarPlugin } from '@mfl/common-components';
import { bootstrapMicrofrontend } from '@mfl/vue-common';

import mfe from './employee-portal-signin-mfe.vue';

const { bootstrap, mount, unmount } = bootstrapMicrofrontend({
  name: 'employee-portal-signin',
  mainComponent: mfe,
  includeCss: true, //['/employee-portal-signin/employee-portal-signin.css'],
  handleVueApp: (app) => {
    useQuasarPlugin(app);
  },
});

export { bootstrap, mount, unmount };
